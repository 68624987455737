import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MasterSidebar from '@components/util/MasterSidebar';
import { companyListsService } from '@services/lists/company_list.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import myClaimsService from '@services/claim/my_claims.service';
import { myTasksService }from '@services/task_management/myTasks.service';
import useRecentVisits from '@rd-web-markets/shared/dist/hooks/useRecentVisits';
import RecentActivity from './RecentActivity';
import MyClaims from './MyClaims';
import MyCompanies from './MyCompanies';
import MyTasks from './MyTasks';
import CommonActions from './CommonActions';

const LandingPage = ({ accountType }) => {
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const [params, setParams] = useState('')
	const [recentClaimGroupIds, recentCompanyIds] = useRecentVisits();

	const [companies] = companyListsService.useGetAll(params, setLoading);
	const [claimGroups] = claimGroupService.useGetAllClaimGroups();
	const [claims] = myClaimsService.useGetAllClaims();
	const [tasks] = myTasksService.useGetAllTasks();

	const { t } = useTranslation();

	return (
		<>
			<MasterSidebar accountType={accountType} />
			<div className='p-3'>
				<h4 className='mb-3'>Welcome, {user?.first_name}</h4>

				<Row>
					<Col lg={8}>
						<RecentActivity
							claimGroups={claimGroups}
							companies={companies}
							recentClaimGroupIds={recentClaimGroupIds}
							recentCompanyIds={recentCompanyIds}
						/>
					</Col>
					<Col lg={4}>
						<MyTasks tasks={tasks} />
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col lg={4}>
						<MyClaims claims={claims} />
					</Col>
					<Col lg={4}>
						<MyCompanies companies={companies} claimGroups={claimGroups} tasks={tasks} />
					</Col>
					<Col lg={4}>
						<CommonActions />
					</Col>
				</Row>
			</div>
		</>
	);
}

export default LandingPage;
