import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Card, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupBreadcrumbLinks from '@components/shared/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupSidebar from './ClaimGroupSidebar';
import ClaimProjectReportsList from '@components/admin/claimProjectReports/ClaimProjectReportsList';
import { useTranslation } from 'react-i18next';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';
import ProjectsList from './ProjectsList';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';
import ProjectGroupingList from './ProjectGroupingList';
import { buildQueryString, parseQueryString } from '@rd-web-markets/shared/dist/services/service';
import { SHOW_PROJECT_GROUPING_TAB_ON_PROJECT_OVERVIEW_PAGE } from '@rd-web-markets/market/dist/constants';
import { SHOW_MARKET_PROGRAM_LIST } from '@rd-web-markets/market/dist/constants';
import ProjectOverviewQuestionnairesPage from './questionnaires/ProjectOverviewQuestionnairesPage';
import ProgramList from '@rd-web-markets/market/dist/claim_group/ProgramList';
import { SHOW_QUESTIONNAIRES_TAB_IN_PROJECT_OVERVIEW } from '@rd-web-markets/market/dist/constants';


const ProjectOverviewPage = ({accountType, handleToaster}) => {
  const history = useHistory()
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = claimGroupService.useGetClaimGroup(claimGroupId)
  const isUnderTechnicalReview = useSelector((state) => state.review.isUnderTechnicalReview)
  const [activeTabKey, setActiveTabKey] = useState(parseQueryString(window.location.search)?.selected_tab || 'projects')
  const { t } = useTranslation();

  if (!claimGroup) return <Loader />

  return (
    <>
      { isUnderTechnicalReview && 
        <Alert key='warning' variant='warning'>
          Claim info can not be edited after manager or client review has started  
        </Alert> 
      }
      <ClaimGroupSidebar claimGroup={claimGroup} highlightedMenuItem='project_overview' />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>

      <Tabs onSelect={(k) => setActiveTabKey(k)} activeKey={activeTabKey} className='mb-3'>
        <Tab eventKey='projects' title={t('project_overview_projects_tab')}>
          { activeTabKey === 'projects' &&
            <ProjectsList additionalQueryParams={{ group_projects: false, selected_tab: activeTabKey }} claimGroup={claimGroup} setClaimGroup={setClaimGroup} accountType={accountType} handleToaster={handleToaster} />
          }
        </Tab>

        {SHOW_PROJECT_GROUPING_TAB_ON_PROJECT_OVERVIEW_PAGE &&
          <Tab eventKey='project_grouping' title={t('project_overview_project_grouping_tab')}>
            { activeTabKey === 'project_grouping' &&
              <ProjectsList additionalQueryParams={{ group_projects: true, selected_tab: activeTabKey }} claimGroup={claimGroup} setClaimGroup={setClaimGroup} accountType={accountType} handleToaster={handleToaster} groupProjects='program' />
            }
          </Tab>
        }

        {SHOW_MARKET_PROGRAM_LIST &&
          <Tab eventKey='project_grouping' title={t('project_overview_project_grouping_tab')}>
            { activeTabKey === 'project_grouping' &&
              <ProgramList  />
            }
          </Tab>
        }

        {SHOW_QUESTIONNAIRES_TAB_IN_PROJECT_OVERVIEW &&
          <Tab eventKey='questionnaire' title={t('questionnaire')}>
            { activeTabKey === 'questionnaire' &&
              <ProjectOverviewQuestionnairesPage claimGroup={claimGroup} accountType={accountType} />
            }
          </Tab>
        }

      </Tabs>
      
    </>
  )
}

export default ProjectOverviewPage