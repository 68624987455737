import useLocalStorage from './useLocalStorage';
export default function useRecentVisits() {
  const [recentClaimGroupIds, setRecentClaimGroupIds] = useLocalStorage('recentClaimGroupIds');
  const [recentCompnayIds, setRecentCompanyIds] = useLocalStorage('recentCompanyIds');
  const addRecentClaimGroupId = id => {
    if (!id) return;
    if (recentClaimGroupIds) {
      const index = recentClaimGroupIds.indexOf(id);
      if (index !== -1) {
        // move the id to the top of the list
        const newRecentVisits = [id, ...recentClaimGroupIds.slice(0, index), ...recentClaimGroupIds.slice(index + 1)];
        setRecentClaimGroupIds(newRecentVisits);
        return;
      } else {
        // Check if no more then 5 recent visits
        // add the id to the top of the list

        const newRecentVisits = (recentClaimGroupIds === null || recentClaimGroupIds === void 0 ? void 0 : recentClaimGroupIds.length) >= 5 ? [id, ...recentClaimGroupIds.slice(0, 4)] : [id, ...recentClaimGroupIds];
        setRecentClaimGroupIds(newRecentVisits);
        return;
      }
    } else {
      setRecentClaimGroupIds([id]);
      return;
    }
  };
  const addRecentCompanyId = id => {
    if (!id) return;
    if (recentCompnayIds) {
      const index = recentCompnayIds.indexOf(id);
      if (index !== -1) {
        const newRecentVisits = [id, ...recentCompnayIds.slice(0, index), ...recentCompnayIds.slice(index + 1)];
        setRecentCompanyIds(newRecentVisits);
        return;
      } else {
        const newRecentVisits = (recentCompnayIds === null || recentCompnayIds === void 0 ? void 0 : recentCompnayIds.length) >= 5 ? [id, ...recentCompnayIds.slice(0, 4)] : [id, ...recentCompnayIds];
        setRecentCompanyIds(newRecentVisits);
        return;
      }
    } else {
      setRecentCompanyIds([id]);
      return;
    }
  };
  return [recentClaimGroupIds, recentCompnayIds, addRecentClaimGroupId, addRecentCompanyId];
}