import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal'
import { 
  SIDEBAR_NAV_ADMIN_LINKS, 
  SIDEBAR_NAV_CLIENT_LINKS, 
  SIDEBAR_NAV_CONSULTANT_LINKS, 
  SIDEBAR_NAV_SUPER_CONSULTANT_LINKS, 
  SIDEBAR_NAV_ACCOUNTANT_LINKS 
} from '@rd-web-markets/market/dist/constants'

const MasterSidebar = ({ accountType, currentPage }) => {
  const user = useSelector((state) => state.auth.user)
  const { t } = useTranslation()

  const menuItems = useMemo(() => {
    let links;

    switch (accountType) {
      case 'admin':
        links = [...SIDEBAR_NAV_ADMIN_LINKS];
        break;
      case 'customer':
        links = [...SIDEBAR_NAV_CLIENT_LINKS];
        break;
      case 'consultant':
        links = [...SIDEBAR_NAV_CONSULTANT_LINKS];
        break;
      case 'super_consultant':
        links = [...SIDEBAR_NAV_SUPER_CONSULTANT_LINKS];
        break;
      case 'accountant':
        links = [...SIDEBAR_NAV_ACCOUNTANT_LINKS];
        break;
      default:
        links = [...SIDEBAR_NAV_ADMIN_LINKS];
    }

    links = links.map(link => {
      if (link.link === currentPage) {
        return { ...link, highlighted: true }
      }

      if (link.subMenu) {
        link = {
          ...link,
          subMenu: link.subMenu.map(subLink => subLink.link === currentPage ? { ...subLink, highlighted: true } : subLink)
        }
      }

      return link;
    })

    if (user && user.show_dashboard && ['/uk', '/us'].includes(process.env.PUBLIC_URL)) {
      links.splice(4, 0, { text: t('dashboards'), link: 'dashboards', icon: 'dashboard', highlighted: currentPage === 'dashboards' })
    }

    return links;
  }, [accountType, currentPage, t, user])

  return (
    <SidebarPortal menuItems={menuItems} headerItem={{ text: 'Home', link: 'admin', icon: 'home' }} dividerPosition={4} />
  )
}

export default MasterSidebar
