import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Row, Button } from 'react-bootstrap';
import { CompanyService } from '@services/company.service';
import claimTemplateCategoriesService from '@services/claim_template_categories.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { SHOW_CLAIM_GROUP_TASKS, SHOW_COST_MENU_IN_MASTER } from '@rd-web-markets/market/dist/constants';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import ClaimGroupNavigation from './ClaimGroupNavigation';
import NoClaimsPage from '@components/shared/claim/NoClaimsPage';
import ClaimGroupMasterPageDashboard from '@rd-web-markets/shared/dist/dashboards/dashboards/ClaimGroupMasterPageDashboard';
import { SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE } from '@rd-web-markets/market/dist/constants';
import { ClaimState } from '@rd-web-markets/market/dist/claim';
import ProjectTaskState from '@rd-web-markets/shared/dist/claim_group/ProjectTaskState';
import claimTemplateTasksService from '@rd-web-markets/shared/dist/services/claim_groups/claim_template_tasks.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import getClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/getClaimGroups.service';
import useRecentVisits from '@rd-web-markets/shared/dist/hooks/useRecentVisits';

const ClaimGroupMasterPage = ({ handleToaster, accountType }) => {
  const { company_id, claim_group_id } = useParams();
  const [company, setCompany] = useState(null);
  const [claimGroup, setClaimGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allClaimGroups, setAllClaimGroups] = useState([]);
  const [recentClaimGroupIds, recentCompnayIds, addRecentClaimGroupId, addRecentCompanyId] = useRecentVisits();

  const getAllClaimGroups = useErrorHandling(useCallback(async () => {
    const allClaimGroups = await getClaimGroupsService.all(company_id);

    const currentYear = (new Date()).getFullYear().toString()
    const currentYearClaimGroup = allClaimGroups.find(cg => cg.name.includes(currentYear))
    const defaultSelectedClaimGroup = process.env.PUBLIC_URL === '/be' ? currentYearClaimGroup : allClaimGroups[0]

    const claimGroup = allClaimGroups.find(c => c.id === Number(claim_group_id)) || defaultSelectedClaimGroup;
    setClaimGroup(claimGroup);
    setAllClaimGroups(allClaimGroups);
  }, [company_id, claim_group_id]));

  const resetCompany = useErrorHandling(useCallback(async () => {
    const company = await CompanyService.get(company_id);
    setCompany(company);
    return company;
  }, [company_id]));

  const resetCompanyAndClaimGroup = useErrorHandling(useCallback(async () => {
    await resetCompany();
  }, [claim_group_id, resetCompany]));

  const updateClaimGroupTask = claimTemplateTasksService.useUpdateClaimGroupTask({ claimGroup, setClaimGroup, setLoading, handleToaster });

  const markTaskComplete = useErrorHandling(useCallback(async (category, categoryIndex, taskIndex, claimGroup) => { //claimGroup task
    setLoading(true);
    const response = await claimTemplateCategoriesService.update(claimGroup.id, category, categoryIndex, taskIndex);
    setClaimGroup({...claimGroup, tasks: response.tasks });
    handleToaster('Task Updated!');
    setLoading(false);
  }, [handleToaster]));

  useEffect(() => {
    getAllClaimGroups();
    resetCompanyAndClaimGroup(company_id, claim_group_id);
    addRecentClaimGroupId(claim_group_id);
    addRecentCompanyId(company_id);
  }, [company_id, claim_group_id, dispatch, resetCompanyAndClaimGroup]);

  if(!company || !allClaimGroups.length) return <Loading />;

  const menuItems = company && allClaimGroups.sort((a, b) => a.name.slice(-4) > b.name.slice(-4) ? -1 : 1).map(claimGroup => {
    // claimGroup.name to replaced to get the year values only
    return { text: claimGroup.name.replace(company.name, '').trim(), link: `claim_groups/${claimGroup.id}/project_overview` }
  })

  const menuItems2 = SHOW_COST_MENU_IN_MASTER && company && allClaimGroups.sort((a, b) => a.name.slice(-4) > b.name.slice(-4) ? -1 : 1).map(claimGroup => {
    // claimGroup.name to replaced to get the year values only
    return { text: claimGroup.name.replace(company.name, '').trim(), link: `companies/${company.id}/${claimGroup.id}/claim_costs` }
  })

  return (
    <>
      <SidebarPortal menuItems={menuItems}
      shouldReload headerItem={{ link: 'companies', text: company.name }}
      title={SHOW_COST_MENU_IN_MASTER && 'bfsz'}
      menuItems2={SHOW_COST_MENU_IN_MASTER && menuItems2}
      title2={SHOW_COST_MENU_IN_MASTER && 'company_cost'}
      />

      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{company.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <div className='d-flex justify-content-between'>
        <h1 className='text-primary mb-4'>
          {company.name}
          <Button className='ml-4' size='lg' variant='primary' as={Link} to={`/${accountType}/companies/${company.id}/edit`}>
            <span className='material-icons'>edit</span>
          </Button>
        </h1>

        { SHOW_ADD_CLAIM_GROUP_BTN_ON_MASTER_PAGE &&
          <Button variant='text' className='h-50' size='lg' as={Link} to={`/${accountType}/companies/add_claim_group/${company.id_in_provider_system}--${company.number}/companies/${company.id}`}>
            <span className='material-icons md-18'>add</span> {t('add')} {t('claim_group')}
          </Button>
        }

      </div>

      { !claimGroup &&
        <NoClaimsPage company={company} accountType={accountType}/>
      }

      { !!claimGroup &&
        <>
          <ClaimGroupNavigation claimGroups={allClaimGroups} claim_group_id={claim_group_id} accountType={accountType} company={company} claimGroupId={claimGroup.id} />

          <Row className='justify-content-end mb-1 mt-3'></Row>

          {!!claimGroup.tasks.length && SHOW_CLAIM_GROUP_TASKS &&
            <>
              <ClaimState tasks={claimGroup.tasks} markTaskComplete={markTaskComplete} updateClaimGroupTask={updateClaimGroupTask} claimGroup={claimGroup} />
              <div className='mt-3 border-bottom border-light' />
            </>
          }
          {
            ['/it', '/de'].includes(process.env.PUBLIC_URL) &&
            <ProjectTaskState accountType={accountType} claimGroup={claimGroup} handleToaster={handleToaster} />
          }

          { !['/it', '/de', '/be'].includes(process.env.PUBLIC_URL) &&
            <ClaimGroupMasterPageDashboard
              companyId={company_id}
              claimGroupId={claim_group_id}
              className='mt-5 mb-5'
            />
          }
        </>
      }
    </>
  );
}

export default ClaimGroupMasterPage;
